@import "./styles/theme.css";
@import "./styles/scroll-bar.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imgList {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 0;
  margin: 0 !important;
}

.colorTrue {
  color: green !important;
}

.colorFalse {
  color: red !important;
}

.fullWidthAutoComplete {
  width: 100%;
}

.showEdit > div {
  box-shadow: none;
  padding-bottom: 0px;
}

.showEditSimpleLayout {
  margin-top: 10px;
  padding: 0px !important;
}

.flat-button-file {
  border: none;
  background-color: #cccccc;
  padding: 7px 13px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.custom-img-field {
  max-height: 10rem;
}

.autoComplete {
  width: "100% !important";
  color: "red";
}
